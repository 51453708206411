import BaseService from '../BaseService';
import CommonResponse from '../common/model/CommonResponse';
import { Service } from '../common/model/Service';
import { CreateUpdateRequest } from './model/Work';

export default class WorkService extends BaseService {
  async getCompanyList(): Promise<CommonResponse> {
    const method = 'GET';
    const url = '/v1/work/company/list';
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }

  async getRequestList(): Promise<CommonResponse> {
    const method = 'GET';
    const url = '/v1/work/request/list';
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }

  async getRequestDetail(requestId: number): Promise<CommonResponse> {
    const method = 'GET';
    const url = '/v1/work/request/' + requestId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }

  async deleteRequest(requestId: number): Promise<CommonResponse> {
    const method = 'DELETE';
    const url = '/v1/work/request/' + requestId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }

  async updateRequest(request: CreateUpdateRequest): Promise<CommonResponse> {
    const method = 'PUT';
    const url = '/v1/work/request/' + request.requestId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = {
      requesterTeam: request.requesterTeam,
      requesterName: request.requesterName,
      requestTitle: request.requestTitle,
      requestContent: request.requestContent,
    };
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }

  async createRequest(request: CreateUpdateRequest): Promise<CommonResponse> {
    const method = 'POST';
    const url = '/v1/work/request';
    const serviceName = Service.BOARD;
    const params = null;
    const body = {
      requesterTeam: request.requesterTeam,
      requesterName: request.requesterName,
      requestTitle: request.requestTitle,
      requestContent: request.requestContent,
      companyId: request.companyId,
    };
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }
    return response;
  }
}
