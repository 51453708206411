import 'bootstrap/dist/css/bootstrap.min.css';
import './ErrorPage.scss';

import React, { ReactElement } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const ErrorPage: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const goBackToPage = () => {
    window.history.back();
  };

  const goToMainPage = () => {
    window.location.assign('/');
  };
  return (
    <div id="ErrorPage">
      <Container>
        <Row>
          <Col>
            <div className="container">
              <div id="ErrorPageTitle" className="ErrorPage-title">
                <span id="ErrorPageTitleTarget" className="ErrorPage-title-target">
                  {t('common.ErrorPage.title')}
                </span>
              </div>
              <div id="ErrorPageContent" className="ErrorPage-content">
                <div className="contents">{t('common.ErrorPage.contents')}</div>
                <div className="contents-sub">{t('common.ErrorPage.contentsSub')}</div>
              </div>
              <div className="ErrorPage-button">
                <Button variant="secondary" className="secondary btn-back" onClick={goBackToPage} id="btnBack">
                  {t('common.ErrorPage.button.back')}
                </Button>
                <span onClick={goToMainPage}>
                  <NavLink to="/" className="nav-link link-main">
                    {t('common.ErrorPage.button.goHome')}
                  </NavLink>
                </span>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};
export default ErrorPage;
