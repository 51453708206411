import BaseService from '../../BaseService';
import { Service } from '../../common/model/Service';
import LoginRequest from './model/LoginRequest';
import SubmitStatus from './model/SubmitStatus';

export default class LoginService {
  public baseService: BaseService = new BaseService();

  async requestLogin(loginRequest: LoginRequest): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus;

    const method = 'POST';
    const url = '/v1/session';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(loginRequest);

    try {
      submitStatus = await this.baseService.fnRest(method, url, null, body, serviceName);
    } catch (error) {
      submitStatus = error as SubmitStatus;
    }

    return submitStatus;
  }

  async requestLogout(): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus;

    const method = 'DELETE';
    const url = '/v1/session';
    const serviceName = Service.MEMBER;

    try {
      submitStatus = await this.baseService.fnRest(method, url, null, null, serviceName);
    } catch (error) {
      submitStatus = error as SubmitStatus;
    }

    return submitStatus;
  }
}
