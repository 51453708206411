export enum CommonDateFormat {
  TIME_DISP = 'a hh:mm',
  DATE_DISP = 'YYYY.MM.DD',
  DATE_IF = 'YYYYMMDD',
  DATETIME_DISP = 'YYYY.MM.DD a hh:mm',
  DATETIME_IF = 'YYYYMMDDHHmmss',
  DATETIME = 'YYYY.MM.DD HH:mm:ss',
  DATETIME_HM_IF = 'YYYYMMDDHHmm',
  DATETIME_HM = 'YYYY-MM-DD HH:mm',
  DATE_FORMAT = 'YYYY-MM-DD',
  HH_MM = 'HH:mm',
  HH_MM_IF = 'HHmm',
}
