import './CommonDateRangePicker.scss';

import moment from 'moment';
import React, { Component, createRef, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker, IntlProvider } from 'rsuite';
import { TypeAttributes } from 'rsuite/lib/@types/common';
import { DateRangePickerProps, RangeType, ValueType } from 'rsuite/lib/DateRangePicker';

import { AuthContext } from '../../App';
import { CommonActionType } from '../../reducer/actions';
import { CommonDateFormat } from '../../service/common/model/enum/CommonDateFormat';
import { DateRangeTypes } from '../../service/common/model/enum/DateRange.enum';
import { Toast } from '../../service/common/model/Toast';
import { getCustomDateLocale, getDateRangeOptions, getDisabledRange } from '../../service/common/utils/util';

interface Props {
  id?: string;
  dateRange?: ValueType;
  optionRanges?: DateRangeTypes[];
  selectAvailableRange?: DateRangeTypes;
  setDateRangePicker: Function;
  cleanDateRangePicker?: Function;
  setIsPickerShow?: Function;
  placement?: TypeAttributes.Placement;
  placeholder?: string;
  renderValue?: any;
  cleanable?: boolean;
  disabled?: boolean;
  closeOverlay?: boolean;
  isPast?: boolean;
  limitDate?: number;
}

const CommonDateRangePicker: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id,
    dateRange,
    optionRanges,
    selectAvailableRange,
    setDateRangePicker,
    cleanDateRangePicker,
    setIsPickerShow,
    placement,
    placeholder,
    renderValue,
    cleanable,
    disabled,
    closeOverlay,
    isPast = true,
    limitDate,
  } = props;

  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const dateRangePickerRef = createRef<Component<DateRangePickerProps>>();

  const defaultCalendarValue: ValueType = [
    moment(new Date()).subtract(1, 'months').startOf('month').toDate(),
    moment(new Date()).endOf('month').toDate(),
  ];

  const defaultRenderValue = (date: ValueType) => {
    const from = moment(date[0]).format(CommonDateFormat.DATE_DISP);
    const to = moment(date[1]).format(CommonDateFormat.DATE_DISP);

    let crawledDate = '';

    if (from === to) {
      crawledDate = to;
    } else {
      crawledDate = from + ' ~ ' + to;
    }

    return (
      <div>
        <span className="label">{props?.placeholder || ' '}</span>
        {crawledDate}
      </div>
    );
  };

  const [ranges, setRanges] = useState<RangeType[]>(getDateRangeOptions(closeOverlay, optionRanges, isPast));
  const handleChangeRange = (date: ValueType) => {
    if (dateRangePickerRef.current) {
      if (`${date[0]?.getFullYear()}_${date[0]?.getMonth()}` !== `${date[1]?.getFullYear()}_${date[1]?.getMonth()}`) {
        dateRangePickerRef.current?.setState({ calendarDate: date });
      } else {
        dateRangePickerRef.current?.setState({
          calendarDate: [date[0], moment(date[1]).add(1, 'M').toDate()],
        });
      }
    }
    setRanges(getDateRangeOptions(closeOverlay, optionRanges, isPast));
    if (closeOverlay) setDateRangePicker(date);
  };
  const onSelect = () => {
    if (dateRangePickerRef.current) {
      const pickerState = dateRangePickerRef.current?.state as any;
      if (pickerState.doneSelected) {
        dateRangePickerRef.current?.setState({ value: pickerState.selectValue });
      }
    }
  };
  const onOk = (date: ValueType) => {
    if (limitDate) {
      const crawledStartDate = moment(date[0], CommonDateFormat.DATE_IF);
      const crawledEndDate = moment(date[1], CommonDateFormat.DATE_IF);
      const dateDiff = moment.duration(crawledEndDate.diff(crawledStartDate)).asDays();

      if (dateDiff > limitDate) {
        const toast: Toast = {} as Toast;
        toast.showToast = true;
        toast.toastMessage = t('common.label.CommonDateRangePicker.toastMessage.invalidDateRange');
        dispatch({ type: CommonActionType.SHOW_TOAST, toast: toast });

        dateRangePickerRef.current?.setState({ value: dateRange });
        setDateRangePicker(dateRange);
      } else {
        setDateRangePicker(date);
      }
    } else {
      if (
        !dateRange ||
        moment(date[0]).format(CommonDateFormat.DATE_DISP) !==
          moment(dateRange[0] || '').format(CommonDateFormat.DATE_DISP) ||
        moment(date[1]).format(CommonDateFormat.DATE_FORMAT) !==
          moment(dateRange[1] || '').format(CommonDateFormat.DATE_DISP)
      ) {
        setDateRangePicker(date);
      }
    }
  };
  const onOpen = () => {
    window.addEventListener('scroll', onScroll, true);
    if (setIsPickerShow) setIsPickerShow(true);
  };
  const onClose = () => {
    if (dateRangePickerRef.current) {
      if (dateRange && dateRange.length > 0) {
        dateRangePickerRef.current?.setState({ calendarDate: dateRange, selectValue: dateRange, value: dateRange });
      } else {
        dateRangePickerRef.current?.setState({ calendarDate: [], selectValue: [], value: [] });
      }
    }
    if (setIsPickerShow) setIsPickerShow(false);
    window.removeEventListener('scroll', onScroll, true);
  };
  const handleCleanRange = () => {
    if (cleanDateRangePicker) cleanDateRangePicker();
  };
  const onScroll = () => {
    const ref = dateRangePickerRef.current as any;
    if (ref) ref.close();
  };

  useEffect(() => {
    onClose();
  }, [dateRange]);  //eslint-disable-line

  return (
    <IntlProvider locale={getCustomDateLocale()}>
      <DateRangePicker
        ref={dateRangePickerRef}
        id={id || ''}
        placeholder={placeholder || ' '}
        appearance={'subtle'}
        placement={placement || 'bottomEnd'}
        ranges={ranges}
        defaultCalendarValue={defaultCalendarValue}
        defaultValue={dateRange}
        showOneCalendar={false}
        cleanable={cleanable || false}
        disabled={disabled || false}
        disabledDate={selectAvailableRange ? getDisabledRange(selectAvailableRange, isPast) : undefined}
        format={CommonDateFormat.DATE_DISP}
        renderValue={renderValue || defaultRenderValue}
        onSelect={onSelect}
        onChange={handleChangeRange}
        onClean={handleCleanRange}
        onOk={onOk}
        onOpen={onOpen}
        onClose={onClose}
      />
    </IntlProvider>
  );
};

export default CommonDateRangePicker;
