import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginSection.scss';

import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from '../../../../icons/common/logo.svg';
import LoginFooter from './LoginFooter';
import LoginForm from './LoginForm';

const LoginSection: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div id="loginSection">
      <Container>
        <Row>
          <Col className="loginContainer-col">
            <div id="LoginSectionTitle" className="LoginSection-title">
              <div id="logo" className="logo">
                <Image src={logo} alt="" />
              </div>
              <div className="loginTitle">
                <h2>
                  <strong>{t('admin.label.LoginSection.main.title')}</strong>
                  <br />
                  {t('admin.label.LoginSection.main.login')}
                </h2>
              </div>
            </div>
            <div id="LoginSectionForm" className="LoginSection-form">
              <Row>
                <LoginForm />
              </Row>
              <Row className="row-footer">
                <LoginFooter />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default LoginSection;
