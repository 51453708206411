import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardListContainer from './BoardListContainer';

const BoardPage1 = (): ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<BoardListContainer />} />
      <Route path="/detail/:id" element={<BoardDetailContainer />} />
    </Routes>
  );
};

export default BoardPage1;
