import './BoardRegistForm.scss';

import { MenuItem, Select } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BoardService from '../../../service/board/BoardService';
import { RegistBoardRequest } from '../../../service/board/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const commonService = new CommonCodeService();
  const boardService = new BoardService();
  const [boardTitle, setBoardTitle] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(event.target.value);
  };

  const handleCategoryOnChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleCancelBtnOnClick = () => {
    navigate('/main/board');
  };

  const handleSaveBtnOnClick = () => {
    // requestData라는 RegistBoardRequest 객체를 선언하여, 각각 boardTitle, selectedCategory, contents값을 할당해줍니다.
    const requestData: RegistBoardRequest = {
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };

    void boardService.createBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        // 성공 시 게시판 목록으로 이동합니다.
        navigate('/main/board');
      }
    });
  };

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setSelectedCategory(res[0].codeId);
        setCategoryList(res);
      }
    });
  }, []);

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          {t('sample.label.FreetalkingBoardSearchSection.title')}
        </span>
        <span className="subTitle">{t('sample.label.FreetalkingBoardInputForm.label.registration')}</span>
      </div>
      <Form className="inputForm">
        <Row className="category-row form-row">
          <Row>
            <Col className="title-col">
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder={t('sample.label.FreetalkingBoardInputForm.placeholder.title')}
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={handleTitleOnChange}
                value={boardTitle}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select
                className="category"
                defaultValue=""
                value={categoryList.length ? selectedCategory : ''}
                onChange={handleCategoryOnChange}
              >
                {categoryList.map((category) => {
                  return (
                    <MenuItem key={category.codeId} value={category.codeId}>
                      {category.codeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Row>
        <Row id="contents" className="form-row">
          <Form.Control as="textarea" maxLength={200} rows={20} onChange={handleContentsOnChange} value={contents} />
        </Row>
        <div className="btnArea">
          <Button
            id="registBtn"
            type="button"
            onClick={handleSaveBtnOnClick}
            disabled={!boardTitle || !contents || !selectedCategory}
          >
            <p>{t('sample.label.FreetalkingBoardInputForm.button.regist')}</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={handleCancelBtnOnClick}>
            <p>{t('sample.label.FreetalkingBoardInputForm.button.cancel')}</p>
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default BoardRegistForm;
