import { CommonAction, CommonActionType, WorkAction, WorkActionType } from './actions';
import { State } from './states';

export const initialState: State = {
  common: {
    isMenuExpanded: true,
  },
  work: {},
};

export const reducer = (state: State, action: CommonAction | WorkAction) => {
  switch (action.type) {
    case CommonActionType.LOADING:
      return {
        ...state,
        common: {
          ...state.common,
          isLoading: action.isLoading,
        },
      };
    case CommonActionType.SHOW_TOAST:
      return {
        ...state,
        common: {
          ...state.common,
          toast: action.toast,
        },
      };
    case CommonActionType.TOGGLE_MENU:
      return {
        ...state,
        common: {
          ...state.common,
          isMenuExpanded: action.isMenuExpanded,
        },
      };
    case CommonActionType.SHOW_CONFIRM_MODAL:
      return {
        ...state,
        common: {
          ...state.common,
          confirmModal: action.confirmModal,
        },
      };
    case WorkActionType.SELECTED_COMPANY:
      return {
        ...state,
        work: {
          ...state.work,
          companyId: action.companyId,
          companyName: action.companyName,
        },
      };
    default:
      return initialState;
  }
};
