export interface CommonCode {
  codeId: string;
  codeName: string;
}

export interface SelectItem {
  value: string;
  label: string;
}

export enum GroupCode {
  CATEGORY = 'CATEGORY',
  STATUS = 'STATUS',
}

export enum CategoryName {
  SOCL = '소셜',
  TECH = '기술',
  ENVR = '환경',
  ECON = '경제',
  POLI = '정치',
  CULT = '문화',
}
