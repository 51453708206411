import './TermsAcceptionModal.scss';

import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TermCheckboxStatus from '../../../../service/member/memberRegistration/model/TermCheckboxStatus';

export interface TermsAcceptionModalI {
  term: TermCheckboxStatus;
  onHide: () => void;
}

export const TermsAcceptionModal: React.FC<TermsAcceptionModalI> = (props: TermsAcceptionModalI) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="terms-acception-modal"
      data-testid="terms-acception-modal-test"
      id="TermsAcceptionModal"
    >
      <Modal.Header closeButton>
        <div className="title bold" data-testid="terms-acception-title">
          {props.term.termsTitle ? (props.term.termsTitle ? props.term.termsTitle : '') : ''}
        </div>
        <div className="modal__header--info">{t('admin.label.TermsAcceptionModal.header')}</div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="divTerms">
            <div
              className="terms-content"
              data-testid="terms-acception-detail"
              dangerouslySetInnerHTML={{
                __html: props.term.termsDetailContents
                  ? props.term.termsDetailContents
                    ? props.term.termsDetailContents
                    : ''
                  : '',
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAcceptionModal;
