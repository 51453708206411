export enum FormFieldCode {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum memberRegistFormFieldCode {
  EMAIL = 'emailAddress',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
  MEMBER_NAME = 'memberName',
}
