import './WorkSideNavSection.scss';

import React, { ReactElement } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function WorkSideNavSection() {
  return (
    <div id="WorkSideNavSection">
      <Nav className="flex-column">
        <NavLink to="/work" className="nav-link">
          협력업체목록
        </NavLink>
        <NavLink to="/request" className="nav-link">
          의뢰요청목록
        </NavLink>
      </Nav>
    </div>
  );
}

export default WorkSideNavSection;
