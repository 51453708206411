import React, { useEffect, useState } from 'react';

import { Company } from '../../../service/work/model/Work';
import WorkService from '../../../service/work/WorkService';
import CompanyInfoCard from './CompanyInfoCard';

const CompanyListContainer = () => {
  const workService = new WorkService();
  const [company, setCompany] = useState<Company[]>([]);

  useEffect(() => {
    void workService.getCompanyList().then((response) => {
      if (response.successOrNot === 'Y') {
        if (response.data.length === 0) {
          alert('조회된 협력업체가 없습니다.');
        } else {
          setCompany(response.data);
        }
      } else {
        alert('협력업체 조회중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
      }
    });
  }, []);  // eslint-disable-line

  return (
    <div id="CompanyListContainer">
      {company.map((value) => {
        return <CompanyInfoCard key={value.companyId} company={value} />;
      })}
    </div>
  );
};

export default CompanyListContainer;
