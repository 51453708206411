import { ConfirmModal } from '../service/common/model/ConfirmModal';
import { Toast } from '../service/common/model/Toast';

export enum CommonActionType {
  LOADING = 'LOADING',
  SHOW_TOAST = 'SHOW_TOAST',
  TOGGLE_MENU = 'TOGGLE_MENU',
  SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL',
}

export type CommonAction = {
  type: CommonActionType;
  isLoading?: boolean;
  toast?: Toast;
  isMenuExpanded?: boolean;
  confirmModal?: ConfirmModal;
};

export enum WorkActionType {
  SELECTED_COMPANY = 'SELECTED_COMPANY',
}

export type WorkAction = {
  type: WorkActionType;
  companyId?: number;
  companyName?: string;
};
