import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage = (): ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<BoardListContainer />} />
      <Route path="/detail/:id" element={<BoardDetailContainer />} />
      <Route path="/regist" element={<BoardRegistContainer />} />
    </Routes>
  );
};

export default BoardPage;
