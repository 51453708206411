import { Session, SessionInfo } from './common/model/SessionInfo';

export default class SessionService {
  public getSessionInfo = (): SessionInfo => {
    let sessionInfo: SessionInfo = {} as SessionInfo;
    if (this.getSessionId() !== '') {
      sessionInfo = {
        sessionId: this.getSessionStorageValue('sessionId'),
        memberId: this.getSessionStorageValue('memberId'),
        memberName: this.getSessionStorageValue('memberName'),
        emailAddress: this.getSessionStorageValue('emailAddress'),
        companyCode: this.getSessionStorageValue('companyCode'),
        companyName: this.getSessionStorageValue('companyName'),
        languageCode: this.getSessionStorageValue('languageCode'),
        roleType: this.getSessionStorageValue('roleType'),
      };
    }
    return sessionInfo;
  };

  public setSessionInfo = (sessionInfo: SessionInfo): void => {
    for (const [key, value] of Object.entries(sessionInfo)) {
      this.setSessionStorageValue(key, value);
    }
  };

  public deleteSessionInfo = (): void => {
    this.deleteSessionStorage();
  };

  public getSessionId = (): string => {
    return this.getSessionStorageValue(Session.SESSION_ID);
  };

  public setSessionId = (sessionId: string): void => {
    this.setSessionStorageValue(Session.SESSION_ID, sessionId);
  };

  public getMemberId = (): string => {
    return this.getSessionStorageValue(Session.MEMBER_ID);
  };

  public setMemberId = (memberId: string): void => {
    this.setSessionStorageValue(Session.MEMBER_ID, memberId);
  };

  private getSessionStorageValue = (key: string): any => {
    if (!key) return '';
    const value = sessionStorage.getItem(key);
    return value && value !== 'undefined' ? sessionStorage.getItem(key) : '';
  };

  private setSessionStorageValue = (key: string, value: any): void => {
    if (key) {
      sessionStorage.setItem(key, value);
    }
  };

  private deleteSessionStorage = (): void => {
    sessionStorage.clear();
  };
}
