import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/ko';
import './App.scss';

import React, { createContext, useReducer } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ErrorPage, LoginPage, MainPage, MemberRegistrationPage, UnauthorizedErrorPage } from './pages';
import WorkPage from './pages/work/WorkPage';
import { initialState, reducer } from './reducer';
import { State } from './reducer/states';
import store from './service/common/utils/store';
import SessionService from './service/SessionService';

interface AuthContextProps {
  state: State;
  dispatch: React.Dispatch<any>;
}

export const AuthContext = createContext({} as AuthContextProps);

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sessionService = new SessionService();

  if (!store.isReady) {
    store.isReady = true;
    store.dispatch = (params) => dispatch(params);
    Object.freeze(store);
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={sessionService.getSessionId() ? <Navigate to="/main" /> : <Navigate to="/login" />}
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/memberRegistration" element={<MemberRegistrationPage />} />
          <Route path="/unauthorized" element={<UnauthorizedErrorPage />} />
          <Route path="/main/*" element={sessionService.getSessionId() ? <MainPage /> : <Navigate to="/login" />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/request" element={<WorkPage />} />
          <Route element={<ErrorPage />} />
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
};
export default App;
