import './MainSection.scss';

import React, { useState } from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import MemberRegistrationService from '../../../../service/member/memberRegistration/MemberRegistrationService';
import StepInformation from '../../../../service/member/memberRegistration/model/StepInformation';
import TermService from '../../../../service/member/memberRegistration/TermService';
import logo from '../.././../../icons/common/logo.svg';
import MemberRegistrationComplete from './MemberRegistrationComplete';
import MemberRegistrationForm from './MemberRegistrationForm';
import { TermsAcception } from './TermsAcception';

export interface MainSectionProps {
  setStepParams: (stepParams: StepInformation) => void;
  stepParams: StepInformation;
}
export const MainSection: React.FC<MainSectionProps> = (props: MainSectionProps) => {
  const { t } = useTranslation();

  const [memberInfo, setMemberInfo] = useState({
    emailAddress: '',
    memberName: '',
  });

  return (
    <div id="MainSection">
      <Container>
        <Row>
          <div className="mainSectionTitle">
            <div id="logo" className="logo">
              <Image src={logo} alt="" />
              <div className="stepStatus">{props.stepParams.currentStep + '/3'}</div>
            </div>
            <h2>
              <strong>{t('admin.label.MainSection.boldTitle' + props.stepParams.currentStep)}</strong>
              <br />
              {t('admin.label.MainSection.subTitle' + props.stepParams.currentStep)}
            </h2>
          </div>
        </Row>
        <Row>
          {props.stepParams.currentStep === 1 && (
            <TermsAcception
              stepParams={props.stepParams}
              setStepParams={props.setStepParams}
              termService={new TermService()}
            />
          )}
          {props.stepParams.currentStep === 2 && (
            <MemberRegistrationForm
              stepParams={props.stepParams}
              setStepParams={props.setStepParams}
              memberRegistrationService={new MemberRegistrationService()}
              setMemberInfo={setMemberInfo}
            />
          )}
          {props.stepParams.currentStep === 3 && <MemberRegistrationComplete memberInfo={memberInfo} />}
        </Row>
      </Container>
    </div>
  );
};
export default MainSection;
