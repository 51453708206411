import './MainPage.scss';
import 'rsuite/dist/styles/rsuite-default.css';

import { ReactElement, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'rsuite';

import { AuthContext } from '../../App';
import NavBar from '../../layouts/NavBar';
import SideNavSection from '../../layouts/SideNavSection';
import CommonSpinner from '../../layouts/Spinner';
import BoardPage from '../board/BoardPage';
import BoardPage1 from '../board1/BoardPage';
import ErrorPage from '../error/ErrorPage';
import EventPage from '../event/EventPage';

const MainPage = (): ReactElement => {
  const { state } = useContext(AuthContext);

  return (
    <div id="MainPage">
      {state.common.isLoading && <Loader backdrop center content={<CommonSpinner />} />}
      <Container>
        <Row id="header-row">
          <NavBar />
        </Row>
        <Row id="body-row" className="d-flex">
          <div id="sideNav" className="sideNav-expanded">
            <SideNavSection />
          </div>
          <Col className="body-content">
            <Routes>
              <Route path="/" element={<Navigate to="/main/board" />} />
              <Route path="/board/*" element={<BoardPage />} />
              <Route path="/board-1/*" element={<BoardPage1 />} />
              {/* <Route path="/board-2" element={<BoardPage2 />} /> */}
              {/* <Route path="/board-3" element={<BoardPage3 />} /> */}
              {/* <Route path="/board-4" element={<BoardPage4 />} /> */}
              {/* <Route path="/board-5" element={<BoardPage5 />} /> */}
              {/* <Route path="/board-6" element={<BoardPage6 />} /> */}
              {/* <Route path="/board-7" element={<BoardPage7 />} /> */}
              {/* <Route path="/board-8" element={<BoardPage8 />} /> */}
              {/* <Route path="/board-9" element={<BoardPage9 />} /> */}
              {/* <Route path="/board-10" element={<BoardPage10 />} /> */}
              {/* <Route path="/board-11" element={<BoardPage11 />} /> */}
              {/* <Route path="/board-12" element={<BoardPage12 />} /> */}
              {/* <Route path="/board-13" element={<BoardPage13 />} /> */}
              {/* <Route path="/board-14" element={<BoardPage14 />} /> */}
              {/* <Route path="/board-15" element={<BoardPage15 />} /> */}
              {/* <Route path="/board-16" element={<BoardPage16 />} /> */}
              {/* <Route path="/board-17" element={<BoardPage17 />} /> */}
              <Route path="/event" element={<EventPage />} />
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default MainPage;
