import './CommonEnterSearchBar.scss';

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormControl } from 'rsuite';

import { AuthContext } from '../../App';
import { CommonActionType } from '../../reducer/actions';
import { Toast } from '../../service/common/model/Toast';
export interface Props {
  id?: string;
  initialValue: string;
  placeholder?: string;
  clearable?: boolean;
  onClear?: Function;
  onSelect: Function;
  lengthLimit?: number;
}

const CommonEnterSearchBar: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  const [value, setValue] = useState<string>(props.initialValue);
  const { dispatch } = useContext(AuthContext);
  return (
    <Form id={props.id} className="enter-search-bar">
      <FormControl
        className={value.length > 0 ? 'on' : ''}
        value={value}
        placeholder={props.placeholder ? props.placeholder : t('common.label.CommonEnterSearchBar.common.placeholder')}
        onChange={(value) => {
          setValue(value.trimLeft());
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            if (props.lengthLimit && value.trim().length < props.lengthLimit) {
              const toast: Toast = {} as Toast;
              toast.showToast = true;
              toast.toastMessage = props.lengthLimit + t('common.label.CommonEnterSearchBar.toastMessage.limitLength');
              dispatch({
                type: CommonActionType.SHOW_TOAST,
                toast: toast,
              });
            } else {
              props.onSelect(value.trim());
            }
          }
        }}
        onBlur={() => {
          setValue(props.initialValue);
        }}
      />
      {props.clearable && value.length > 0 && (
        <Button
          onClick={() => {
            if (props.onClear) {
              props.onClear();
            }
          }}
          id={'CommonEnterSearchBarValueClearBtn'}
        >
          {'X'}
        </Button>
      )}
    </Form>
  );
};

export default CommonEnterSearchBar;
