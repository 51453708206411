import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../App';
import { WorkActionType } from '../../../reducer/actions';
import { Company } from '../../../service/work/model/Work';

interface Props {
  company: Company;
}
function CompanyInfoCard({ company }: Props) {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleBtnOnClick = () => {
    dispatch({
      type: WorkActionType.SELECTED_COMPANY,
      companyId: company.companyId,
      companyName: company.companyName,
    });
    navigate('/request/enroll');
  };

  return (
    <div id="CompanyInfoCard">
      <Card>
        <Card.Body>
          <Row>
            <Col>{company.companyName}</Col>
            <Col>
              <div>업무영역 : {company.businessArea}</div>
              <div>수행이력 : {company.businessHistory}</div>
            </Col>
          </Row>
          <Row>
            <Button variant="success" onClick={handleBtnOnClick}>
              의뢰하기
            </Button>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CompanyInfoCard;
