import i18next from 'i18next';

import BaseService from '../BaseService';
import { CommonCode } from './model/CommonCode';
import CommonResponse from './model/CommonResponse';
import { Service } from './model/Service';

const mockResponse: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {},
};
export default class CommonCodeService extends BaseService {
  public async getCommonCode(groupCodeId: string, keyword?: string): Promise<CommonCode[]> {
    let response: CommonResponse;
    // const { t } = useTranslation();

    const method = 'GET';
    const url = '/v1/common/codes';
    const serviceName = Service.BOARD;
    const params = { groupCodeId: groupCodeId };
    const body = null;

    mockResponse.data = [
      {
        codeId: 'SOCL',
        codeName: i18next.language === 'ko' ? '소셜' : 'Social',
      },
      {
        codeId: 'TECH',
        codeName: i18next.language === 'ko' ? '기술' : 'Techniques',
      },
      {
        codeId: 'ENVR',
        codeName: i18next.language === 'ko' ? '환경' : 'Environment',
      },
      {
        codeId: 'ECON',
        codeName: i18next.language === 'ko' ? '경제' : 'Economy',
      },
      {
        codeId: 'POLI',
        codeName: i18next.language === 'ko' ? '정치' : 'Politics',
      },
      {
        codeId: 'CULT',
        codeName: i18next.language === 'ko' ? '문화' : 'Culture',
      },
    ];

    try {
      response = mockResponse;
      // response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error as CommonResponse;
    }

    return response.successOrNot === 'Y' ? Promise.resolve(response.data) : [];
  }
}
