import './MemberRegistrationComplete.scss';

import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface Props {
  memberInfo: { memberName: string; emailAddress: string };
}
const MemberRegistrationComplete: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div id="MemberRegistrationComplete">
      <div className="memberInfo">
        <div className="info-line">
          <div className="dot"></div>
          <div className="infoTitle">{t('admin.label.MemberRegistrationComplete.memberName')}</div>
          <div className="info">{props.memberInfo.memberName}</div>
        </div>
        <div className="info-line">
          <div className="dot"></div>
          <div className="infoTitle">{t('admin.label.MemberRegistrationComplete.emailAddrress')}</div>
          <div className="info">{props.memberInfo.emailAddress}</div>
        </div>
      </div>
      <div className="guideMessage">{t('admin.label.MemberRegistrationComplete.completeMessage')}</div>
      <Button className="toLogin" onClick={() => navigate('/')}>
        {t('admin.label.MemberRegistrationComplete.returnToLogin')}
      </Button>
    </div>
  );
};

export default MemberRegistrationComplete;
