import './BoardListSection.scss';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import MaterialTable, { Column, Options } from 'material-table';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import TablePagination from 'rsuite/lib/Table/TablePagination';

import { BoardData, BoardSearchCondition } from '../../../service/board/model/Board';
import { pageSizeOptions } from '../../../service/common/model/Common';
import { CategoryName } from '../../../service/common/model/CommonCode';
import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';
import { useWindowDimensions } from '../../../service/common/utils/useWindowDimensions';

interface Props {
  defaultSearchCondition: BoardSearchCondition;
  searchCondition: BoardSearchCondition;
  onSearchConditionChange: Function;
  boardList: BoardData[];
  totalCount: number;
}

const BoardListSection: React.FC<Props> = (props: Props): ReactElement => {
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(0);
  const { t } = useTranslation();

  const handleRowClick = (rowData) => {
    navigate('/main/board-1/detail/' + rowData.boardId);
  };

  const countPageNum = () => {
    const totalCount = props.totalCount;
    const pageSize = props.searchCondition.pageSize;
    let quo = totalCount / pageSize;
    const rem = totalCount % pageSize;
    if (rem !== 0) quo = quo + 1;
    if (quo > 5) quo = 5;
    setTotalPage(quo);
  };
  useEffect(() => {
    countPageNum();
  }, [props]);

  const selectPicker = pageSizeOptions.map((value) => {
    return { value: value, label: value + t('common.label.pagingLabel') };
  });

  return (
    <div id="BoardListSection">
      <div id={'MaterialTableWrapper'} className={'MaterialTableWrapper'}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 840 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ minWidth: 480, maxWidth: 880 }}>
                  {t('sample.label.FreetalkingBoardListSection.title')}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 100, maxWidth: 150 }}>
                  {t('sample.label.FreetalkingBoardListSection.category')}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 140, maxWidth: 280 }}>
                  {t('sample.label.FreetalkingBoardListSection.writer')}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 120, maxWidth: 180 }}>
                  {t('sample.label.FreetalkingBoardListSection.writeDate')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ minHeight: 90, maxHeight: height - 200 }}>
              {props.boardList.map((rowData) => (
                <TableRow
                  key={rowData.boardId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => handleRowClick(rowData)}
                  hover={true}
                >
                  <TableCell align="left" sx={{ minWidth: 480, maxWidth: 880 }}>
                    {rowData.boardTitle.length > 10
                      ? (rowData.boardTitle || '').substring(0, 50) + '...'
                      : rowData.boardTitle || ''}
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 100, maxWidth: 150 }}>
                    {CategoryName[rowData.category]}
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 140, maxWidth: 280 }}>
                    {rowData.createMemberName}
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 120, maxWidth: 180 }}>
                    {rowData.createdDatetime ? moment(rowData.createdDatetime).format(CommonDateFormat.DATE_DISP) : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div id="BoardListSectionFooter">
        <TablePagination
          style={{ float: 'left' }}
          showInfo={false}
          renderLengthMenu={() => {
            return (
              <SelectPicker
                data={selectPicker}
                valueKey={'value'}
                menuClassName="pagination-select-menu"
                value={props.searchCondition.pageSize}
                cleanable={false}
                searchable={false}
                placement={'topStart'}
                onChange={(pageSize: number) => {
                  props.onSearchConditionChange({ ...props.searchCondition, pageSize: pageSize });
                }}
              />
            );
          }}
          pages={totalPage}
          activePage={props.searchCondition.pageIndex + 1}
          displayLength={props.searchCondition.pageSize || 15}
          total={props.totalCount}
          ellipsis={true}
          boundaryLinks={true}
          onChangePage={(pageNumber: number) => {
            props.onSearchConditionChange({ ...props.searchCondition, pageIndex: pageNumber - 1 });
          }}
        />
        <Button id="registButton" type="button" className="btn-regist"></Button>
      </div>
    </div>
  );
};

export default BoardListSection;
