import './CommonCheckPicker.scss';

import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Button, CheckPicker } from 'rsuite';
import { FormControlPickerProps, ItemDataType } from 'rsuite/lib/@types/common';

import { PickerItem } from '../../service/common/model/PickerItem.model';

export interface Props {
  id: string;
  onSelect: Function;
  onClean?: Function;
  renderValue?: Function;
  initialValue: Array<string>;
  data: Array<PickerItem>;
  selectAllKey: string;
  placeholder?: string;
  footerLabel?: string;
  disabled?: boolean;
}

const CommonCheckPicker: React.FC<Props> = (props: Props): ReactElement => {
  const { id, onSelect, onClean, renderValue, initialValue, data, selectAllKey, placeholder, footerLabel, disabled } =
    props;
  const [items, setItems] = useState<Array<string>>(initialValue);
  const checkPickerRef = createRef<FormControlPickerProps>();

  const handleRenderValue = (value: any, item: ItemDataType | ItemDataType[], selectedElement: React.ReactNode) => {
    if (renderValue) {
      return renderValue(value, item, selectedElement, id, placeholder);
    } else {
      if (items.includes(selectAllKey)) {
        return;
      } else {
        return selectedElement;
      }
    }
  };

  const handleRenderMenuItem = (label: React.ReactNode, item: ItemDataType) => {
    return <p className="common-picker-item">{label}</p>;
  };

  const handleRenderExtraFooter = () => {
    const handleOnClick = () => {
      checkPickerRef.current?.close();
      onSelect(items);
    };

    return (
      <div className="picker-footer">
        <Button onClick={handleOnClick}>{footerLabel ? footerLabel : ''}</Button>
      </div>
    );
  };

  useEffect(() => {
    if (!checkPickerRef.current?.state.active) {
      setItems(initialValue);
    }
  }, [initialValue]); // eslint-disable-line

  return (
    <CheckPicker
      ref={checkPickerRef}
      id={id}
      disabled={disabled || false}
      onChange={(value) => {
        if (items.includes(selectAllKey)) {
          setItems(
            value.filter((val) => {
              return val !== selectAllKey;
            })
          );
        } else {
          if (value.includes(selectAllKey)) {
            setItems([selectAllKey]);
          } else {
            setItems(value);
          }
        }
      }}
      menuAutoWidth={true}
      menuClassName={'menuOption'}
      value={items}
      placeholder={placeholder}
      data={data}
      searchable={false}
      renderValue={handleRenderValue}
      renderMenuItem={handleRenderMenuItem}
      renderExtraFooter={handleRenderExtraFooter}
      onClose={() => {
        setItems(initialValue);
      }}
      onClean={() => {
        setItems([]);
        if (onClean) onClean();
      }}
    />
  );
};
export default CommonCheckPicker;
