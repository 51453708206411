export interface SessionInfo {
  sessionId: string;
  memberId: number;
  memberName: string;
  emailAddress: string;
  companyCode: string;
  companyName: string;
  languageCode: string;
  roleType: string;
}

export enum Session {
  SESSION_ID = 'sessionId',
  MEMBER_ID = 'memberId',
  MEMBER_NAME = 'memberName',
  EMAIL_ADDRESS = 'emailAddress',
  COMPANY_CODE = 'companyCode',
  COMPANY_NAME = 'companyName',
  LANGUAGE_CODE = 'languageCode',
  ROLE_TYPE = 'roleType',
}
